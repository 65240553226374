import "jsqr";

// https://zenn.dev/sdkfz181tiger/articles/096dfb74d485db
window.onload = (e) => {
  $("#unlockRequestButton").on("click", function(e) {
    $("#unlockRequestButton").prop("disabled", true);
    let url = new URL(location.href);
    $.ajax({
      url: "/orders/unlock",
      type: "POST",
      data: {
        id: url.searchParams.get("id")
      },
      dataType: "script"
    });
  });

  $("#launch-qr-reader-btn").on('click', function() {
    let video  = document.createElement("video");
    let canvas = document.getElementById("canvas");
    let ctx = canvas.getContext("2d");
    let msg = document.getElementById("msg");
    let qrCode = $("#pickup-location-qr-code").val();
    console.log(`qr code ${qrCode}`);

    const userMedia = { video: { facingMode: "environment" } };
    navigator.mediaDevices.getUserMedia(userMedia).then((stream) => {
      video.srcObject = stream;
      video.setAttribute("playsinline", true);
      video.play();
      startTick();
    }).catch((error) => {
      msg.innerText = `不明なエラーが発生しました（${error}）`
    });

    function startTick() {
      msg.innerText = "カメラを起動中";
      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        canvas.height = video.videoHeight;
        canvas.width = video.videoWidth;
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        let img = ctx.getImageData(0, 0, canvas.width, canvas.height);
        let code = jsQR(img.data, img.width, img.height, { inversionAttempts: "dontInvert" });

        if (code) {
          drawRect(code.location);
          if (code.data === qrCode) {
            msg.innerText = "QRコードの認証に成功しました";
            stopVideo();
            $("#qrReaderModal").modal("hide");
            $("#unlockModal").modal("show");
          } else {
            msg.innerText = "QRコードが不正です。受取場所を確認してください";
          }
        } else {
          msg.innerText = "QRコードを読み取ってください";
        }
      }
      setTimeout(startTick, 400);
    }

    function stopVideo() {
      video.pause();
      video.src = "";
      video.srcObject.getTracks()[0].stop();
    }

    function drawRect(location) {
      drawLine(location.topLeftCorner, location.topRightCorner);
      drawLine(location.topRightCorner, location.bottomRightCorner);
      drawLine(location.bottomRightCorner, location.bottomLeftCorner);
      drawLine(location.bottomLeftCorner, location.topLeftCorner);
    }

    function drawLine(begin, end) {
      ctx.lineWidth = 4;
      ctx.strokeStyle = "#FF3B58";
      ctx.beginPath();
      ctx.moveTo(begin.x, begin.y);
      ctx.lineTo(end.x, end.y);
      ctx.stroke();
    }
  });
}
