document.addEventListener('DOMContentLoaded', () => {
  $("#selling_price_select").on("change", function(e) {
		console.log(e.target.value);
		const items = $('#items-data').data('items');
		console.log(items);

		const selling_pricies = document.getElementsByClassName('selling_price');
		
		if(e.target.value == '10per_off'){
			SetSellingPrice(selling_pricies, items, 'sale_price_10_discount_incl_tax')
		}else if(e.target.value == '20per_off'){
			SetSellingPrice(selling_pricies, items, 'sale_price_20_discount_incl_tax')
		}else if(e.target.value == '30per_off'){
			SetSellingPrice(selling_pricies, items, 'sale_price_30_discount_incl_tax')
		}else if(e.target.value == '40per_off'){
			SetSellingPrice(selling_pricies, items, 'sale_price_40_discount_incl_tax')
		}else if(e.target.value == '50per_off'){
			SetSellingPrice(selling_pricies, items, 'sale_price_50_discount_incl_tax')
		}else if(e.target.value == '60per_off'){
			SetSellingPrice(selling_pricies, items, 'sale_price_60_discount_incl_tax')
		}else if(e.target.value == '70per_off'){
			SetSellingPrice(selling_pricies, items, 'sale_price_70_discount_incl_tax')
		}else if(e.target.value == '80per_off'){
			SetSellingPrice(selling_pricies, items, 'sale_price_80_discount_incl_tax')
		}else if(e.target.value == '90per_off'){
			SetSellingPrice(selling_pricies, items, 'sale_price_90_discount_incl_tax')
		}
	})

	function SetSellingPrice(selling_pricies, items, selling_price_per_off){
		for(let i = 0; i < selling_pricies.length; i++){
			selling_pricies[i].textContent = items[i][selling_price_per_off];
		}
	}
});