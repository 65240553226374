$(function(){
  $('#search-reset').on('click', function(){
    $('#product_id').val('');
    $('#product_name').val('');
    $('#expiration_date_at_gteq').val('');
    $('#expiration_date_at_lteq_end_of_day').val('');
    $('#list_price_incl_tax').val('');
    $('#purchase_unit').val('');
    $('#purchase_quantity').val('');
    $('#purchase_price_incl_tax').val('');
    $('#stock_quantity').val('');
    $('#order_unit').val('');
    $('#quantity_requested').val('');
    $('#time_discount_apply_flag').val('');
  });
});